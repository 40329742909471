import { User } from '@/types/Auth.types';
import axiosInstance from '@/utils/https';

export type UserProps = {
  first_name: string;
  last_name: string;
  telephone: string;
  email: string;
  password: string;
};

export type UserEmailProps = {
  emailOrPhone: string;
};
export type VerifyProps = {
  emailOrPhone: string;
  otp: string;
};
export type UpdateUserProps = {
  emailOrPhone: string;
  firstName: string;
  lastName: string;
};
type FansProps = {
  name: string;
  sex: string;
  address: string;
  phone_number: string;
  image_url: string;
};

export const Register = async (payload: UserProps) => {
  try {
    const url = `api/v1/auth/customer/register`;
    const response = await axiosInstance.post(url, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const RegisterEmailOrPhone = async (payload: UserEmailProps) => {
  try {
    const url = `/api/v1/users/billing-verification`;
    const response = await axiosInstance.post(url, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const verifyOtp = async (payload: VerifyProps) => {
  try {
    const url = `/api/v1/users/verify-otp`;
    const response = await axiosInstance.post(url, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const updateUser = async (payload: UpdateUserProps) => {
  try {
    const url = `/api/v1/users/update-name`;
    const response = await axiosInstance.post(url, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const InitiateOTPLogin = async (payload: { email?: string; telephone?: string }) => {
  try {
    const url = `api/v1/auth/otp/login`;
    const response = await axiosInstance.post(url, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const VerifyOTPLogin = async (payload: { "token": string; }) => {
  try {
    const url = `api/v1/auth/otp/login/verify`;
    const response = await axiosInstance.post(url, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const Login = async (payload: { email: string; password: string }) => {
  try {
    const url = `api/v1/auth/login`;
    const response = await axiosInstance.post(url, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const refreshUser = async () => {
  try {
    const url = `api/v1/auth/me`;
    const response = await axiosInstance.get(url);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetUserByEmail = async (email: string) => {
  try {
    const url = "api/v1/auth/me/email?email="+email;
    const response = await axiosInstance.get(url);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GoogleAuth = async (params: any) => {
  try {
    const url = `api/v1/auth/google/callback?${params}`;

    const res = await axiosInstance.get(url);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const JoinNewsletter = async (email: string) => {
  try {
    const url = 'api/v1/newsletters';
    const res = await axiosInstance.post(url, { email_address: email });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const GetNews = async () => {
  try {
    const url = '/api/v1/news';
    const res = await axiosInstance.get(url);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const JoinFanClub = async (payload: FansProps) => {
  try {
    const url = '/api/v1/fans';
    const res = await axiosInstance.post(url, payload);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const InitiateOTPAccountDeletion = async () => {
  try {
    const url = `api/v1/auth/initiate-delete-account`;
    const response = await axiosInstance.post(url);

    return response.data;
  } catch (error) {
    throw error;
  }
}
export const DeleteAccount = async () => {
  try {
    const url = `api/v1/auth/delete-account`;
    const response = await axiosInstance.post(url);

    return response.data;
  } catch (error) {
    throw error;
  }
}